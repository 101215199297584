import { photomontage, viewpoints } from "./mapData/photoGeoJSON";

export const generalConfig = {
  googleTranslate: {
    enabled: true,
    languages: [
      { text: "لغة", code: "ar" },
      { text: "语言", code: "zh-CN" },
      { text: "Γλώσσα", code: "el" },
      { text: "Linguaggio", code: "it" },
      { text: "언어", code: "ko" },
      { text: "Ngôn ngữ", code: "vi" },
    ],
  },
};

export const mapConfig = {
  commonLegend: [],
  geocoderConfig: {
    moduleComponentName: "MapboxGeocoder",
    searchBoundary: {
      minX: 140.968895,
      minY: -36.448435,
      maxX: 148.616162,
      maxY: -32.91406,
    },
  },
  icons: [
    {
      id: "ocp-custom-camera-viewpoint-icon-mapbox",
      fileName: "camera-viewpoint.png",
    },
    {
      id: "ocp-custom-camera-photomontage-icon-mapbox",
      fileName: "camera-photomontage.png",
    },
  ],
  layerControls: [
    {
      ids: ["tece-easement-330kv"],
      label: "330kV transmission line easement",
    },
    {
      ids: ["tece-easement-550kv"],
      label: "500kV transmission line easement",
    },
    {
      ids: ["tece-cia-updated"],
      label: "Indicative construction impact area",
    },

    {
      ids: ["tece-buronga-substation"],
      label: "Buronga substation",
    },
    {
      ids: ["tece-balranald-substation"],
      label: "Balranald substation",
    },
    {
      ids: ["dinawan-substation-site"],
      label: "Dinawan substation site",
    },
    {
      ids: ["tece-waggawagga-substation"],
      label: "Wagga Wagga substation upgrade and expansion area",
    },
    {
      ids: ["tece-optical-repeater", "tece-optical-repeater-label"],
      label: "Optical repeater site locations",
    },
    {
      ids: ["tece-water-fill-points"],
      label: "Water fill points",
    },
    {
      ids: ["tece-existing-transmission-infrastructure"],
      label: "Existing transmission line infrastructure",
    },
    {
      ids: ["tece-west-construction", "tece-west-corridor"],
      label: "EnergyConnect (NSW - Western section)",
    },
    {
      ids: ["tece-waterways"],
      label: "Existing waterways",
    },
    {
      ids: ["tece-sensitive-recievers"],
      label: "Sensitive receivers",
    },
    {
      ids: ["tece-town-label"],
      label: "Town names",
    },
    {
      ids: ["tece-state-forests"],
      label: "State forests and conservation areas",
    },
    {
      ids: ["tece-travelling-stock-routes"],
      label: "Travelling stock reserves",
    },
    {
      ids: ["tece-cadastre"],
      label: "Cadastral boundaries",
    },
    {
      ids: ["tece-main-construction-compound"],
      label: "Main construction compounds and accomodation sites",
    },
  ],
  layers: [
    {
      id: "photo-points-viewpoint",
      type: "symbol",
      source: {
        type: "geojson",
        data: viewpoints,
      },
      layout: {
        "icon-image": "ocp-custom-camera-viewpoint-icon-mapbox",
        "icon-allow-overlap": true,
        "icon-size": 0.4,
      },
    },
    {
      id: "photo-points-photomontage",
      type: "symbol",
      source: {
        type: "geojson",
        data: photomontage,
      },
      layout: {
        "icon-image": "ocp-custom-camera-photomontage-icon-mapbox",
        "icon-allow-overlap": true,
        "icon-size": 0.4,
      },
    },
    {
      id: "tece-balranald-substation",
      mapboxStyle: "mapbox://styles/energyconnect/ckwvl3aol5ytc14o6gmp74e3j",
      "source-layer": "BalranaldSubstation-5gg2e7",
      type: "circle",
      source: {
        type: "vector",
        url: "mapbox://energyconnect.70o9pknw",
      },
    },
    {
      id: "tece-buronga-substation",
      mapboxStyle: "mapbox://styles/energyconnect/ckwvl3aol5ytc14o6gmp74e3j",
      "source-layer": "BurongaSubstation-3tmx17",
      type: "circle",
      source: {
        type: "vector",
        url: "mapbox://energyconnect.79i2xydy",
      },
    },
    {
      id: "tece-optical-repeater-label",
      mapboxStyle: "mapbox://styles/energyconnect/ckwvl3aol5ytc14o6gmp74e3j",
      "source-layer": "OpticalRepeater-29523p",
      type: "symbol",
      source: {
        type: "vector",
        url: "mapbox://energyconnect.cik3qttv",
      },
    },
    {
      id: "tece-town-label",
      mapboxStyle: "mapbox://styles/energyconnect/ckwvl3aol5ytc14o6gmp74e3j",
      "source-layer": "TownNames-1qz5al",
      type: "symbol",
      source: {
        type: "vector",
        url: "mapbox://energyconnect.9keb8ee6",
      },
    },
    {
      id: "tece-optical-repeater",
      mapboxStyle: "mapbox://styles/energyconnect/ckwvl3aol5ytc14o6gmp74e3j",
      "source-layer": "OpticalRepeater-29523p",
      type: "circle",
      source: {
        type: "vector",
        url: "mapbox://energyconnect.cik3qttv",
      },
    },
    {
      id: "tece-town",
      mapboxStyle: "mapbox://styles/energyconnect/ckwvl3aol5ytc14o6gmp74e3j",
      "source-layer": "TownNames-1qz5al",
      type: "circle",
      source: {
        type: "vector",
        url: "mapbox://energyconnect.9keb8ee6",
      },
    },
    {
      id: "tece-water-fill-points",
      mapboxStyle: "mapbox://styles/energyconnect/ckwvl3aol5ytc14o6gmp74e3j",
      "source-layer": "WaterFillPoints-1gyxak",
      type: "circle",
      source: {
        type: "vector",
        url: "mapbox://energyconnect.54y1hhjq",
      },
    },
    {
      id: "tece-sensitive-recievers",
      mapboxStyle: "mapbox://styles/energyconnect/ckwvl3aol5ytc14o6gmp74e3j",
      "source-layer": "SensitiveRecievers-dczix3",
      type: "circle",
      source: {
        type: "vector",
        url: "mapbox://energyconnect.6gurhfel",
      },
    },
    {
      id: "tece-waterways",
      mapboxStyle: "mapbox://styles/energyconnect/ckwvl3aol5ytc14o6gmp74e3j",
      "source-layer": "ExistingWaterways-b3elx9",
      type: "line",
      source: {
        type: "vector",
        url: "mapbox://energyconnect.41dsof0l",
      },
    },
    {
      id: "tece-easement-330kv",
      mapboxStyle: "mapbox://styles/energyconnect/ckwvl3aol5ytc14o6gmp74e3j",
      "source-layer": "TransmissionLineEasement_330k-6h641c",
      type: "line",
      source: {
        type: "vector",
        url: "mapbox://energyconnect.bn6iuno6",
      },
    },
    {
      id: "tece-easement-550kv",
      mapboxStyle: "mapbox://styles/energyconnect/ckwvl3aol5ytc14o6gmp74e3j",
      "source-layer": "TransmissionLineEasement_500k-d6zhar",
      type: "line",
      source: {
        type: "vector",
        url: "mapbox://energyconnect.a5930s25",
      },
    },
    {
      id: "tece-existing-transmission-infrastructure",
      mapboxStyle: "mapbox://styles/energyconnect/ckwvl3aol5ytc14o6gmp74e3j",
      "source-layer": "ExistingTransmissionLineInfra-cg14e9",
      type: "line",
      source: {
        type: "vector",
        url: "mapbox://energyconnect.4cgh3snj",
      },
    },
    {
      id: "tece-travelling-stock-routes",
      mapboxStyle: "mapbox://styles/energyconnect/ckwvl3aol5ytc14o6gmp74e3j",
      "source-layer": "TravellingStockRoutes-593ruv",
      type: "fill",
      source: {
        type: "vector",
        url: "mapbox://energyconnect.07irzp4o",
      },
    },
    {
      id: "tece-state-forests",
      mapboxStyle: "mapbox://styles/energyconnect/ckwvl3aol5ytc14o6gmp74e3j",
      "source-layer": "StateForestsandConservationAr-axgoty",
      type: "fill",
      source: {
        type: "vector",
        url: "mapbox://energyconnect.3k8g9267",
      },
    },
    {
      id: "tece-registered-gw-boreholes",
      mapboxStyle: "mapbox://styles/energyconnect/ckwvncr615ujd14s1ujh9nald",
      "source-layer": "Groundwater_RegisteredGroundW-711sl5",
      type: "circle",
      source: {
        type: "vector",
        url: "mapbox://energyconnect.46izq7dc",
      },
    },
    {
      id: "tece-operational-noise-transmission",
      mapboxStyle: "mapbox://styles/energyconnect/ckwvncr615ujd14s1ujh9nald",
      "source-layer": "OperationalNoise_Transmission-1ehwl9",
      type: "circle",
      source: {
        type: "vector",
        url: "mapbox://energyconnect.3ejhvffl",
      },
    },
    {
      id: "tece-construction-noise-buffer-standard",
      mapboxStyle: "mapbox://styles/energyconnect/ckwvncr615ujd14s1ujh9nald",
      "source-layer": "ConstructionNoiseBuffer_Trans-4g545e",
      type: "circle",
      source: {
        type: "vector",
        url: "mapbox://energyconnect.88visrq9",
      },
    },
    {
      id: "tece-construction-noise-buffer-night",
      mapboxStyle: "mapbox://styles/energyconnect/ckwvncr615ujd14s1ujh9nald",
      "source-layer": "ConstructionNoiseBuffer_Trans-8je6q9",
      type: "circle",
      source: {
        type: "vector",
        url: "mapbox://energyconnect.6tjioa5o",
      },
    },
    {
      id: "tece-construction-noise-buffer-day",
      mapboxStyle: "mapbox://styles/energyconnect/ckwvncr615ujd14s1ujh9nald",
      "source-layer": "ConstructionNoiseBuffer_Trans-6nqwdd",
      type: "circle",
      source: {
        type: "vector",
        url: "mapbox://energyconnect.9r84iwzs",
      },
    },
    {
      id: "tece-previously-recorded-ahims",
      mapboxStyle: "mapbox://styles/energyconnect/ckwvncr615ujd14s1ujh9nald",
      "source-layer": "NewRecordedAHIMSSite-dy7t7n",
      type: "circle",
      source: {
        type: "vector",
        url: "mapbox://energyconnect.apmcp9h3",
      },
    },
    {
      id: "tece-new-recorded-ahims",
      mapboxStyle: "mapbox://styles/energyconnect/ckwvncr615ujd14s1ujh9nald",
      "source-layer": "PreviouslyRecordedAHIMSLocati-dedaem",
      type: "circle",
      source: {
        type: "vector",
        url: "mapbox://energyconnect.31wxt86g",
      },
    },
    {
      id: "tece-proposed-haulage-routes-updated",
      mapboxStyle: "mapbox://styles/energyconnect/ckycfbb7n0x9a15nukuf7cxtc",
      "source-layer": "TRANS_WSP_PECe_TrafficRoutes_-7i9pjl",
      type: "line",
      source: {
        type: "vector",
        url: "mapbox://energyconnect.6hphjf4k",
      },
    },
    {
      id: "tece-landuse-inherent-soil-fertility",
      mapboxStyle: "mapbox://styles/energyconnect/ckwvncr615ujd14s1ujh9nald",
      "source-layer": "LandUse_InherentSoilFertility-6tyb1b",
      type: "fill",
      source: {
        type: "vector",
        url: "mapbox://energyconnect.cqi1h3id",
      },
    },
    {
      id: "tece-landuse-crown",
      mapboxStyle: "mapbox://styles/energyconnect/ckwvncr615ujd14s1ujh9nald",
      "source-layer": "LandUse_CrownLand-9avcx1",
      type: "fill",
      source: {
        type: "vector",
        url: "mapbox://energyconnect.55nrrg3g",
      },
    },
    {
      id: "tece-catchments",
      mapboxStyle: "mapbox://styles/energyconnect/ckwvncr615ujd14s1ujh9nald",
      "source-layer": "Hydrology_CatchmentBoundaries-1xtnzw",
      type: "fill",
      source: {
        type: "vector",
        url: "mapbox://energyconnect.3ww6nrdh",
      },
    },
    {
      id: "tece-aquatic-gde",
      mapboxStyle: "mapbox://styles/energyconnect/ckwvncr615ujd14s1ujh9nald",
      "source-layer": "Groundwater_AquaticGroundwate-195lgn",
      type: "fill",
      source: {
        type: "vector",
        url: "mapbox://energyconnect.30omyus6",
      },
    },
    {
      id: "tece-cia-updated",
      mapboxStyle: "mapbox://styles/energyconnect/ckycfbb7n0x9a15nukuf7cxtc",
      "source-layer": "CONSTRUCTION_WSP_PECe_Constru-cvw8e5",
      type: "line",
      source: {
        type: "vector",
        url: "mapbox://energyconnect.82dsxa5t",
      },
    },
    {
      id: "tece-main-construction-compound",
      mapboxStyle: "mapbox://styles/energyconnect/ckwvncr615ujd14s1ujh9nald",
      "source-layer": "MainConstructionCompoundsandA-cufffm",
      type: "fill",
      source: {
        type: "vector",
        url: "mapbox://energyconnect.az0utt6w",
      },
    },
    {
      id: "tece-disturbance-areas",
      mapboxStyle: "mapbox://styles/energyconnect/ckwvok38f1b2o15oe94joa0r0",
      "source-layer": "DisturbanceAreas_v8_211207_GD-07scw7",
      type: "fill",
      source: {
        type: "vector",
        url: "mapbox://energyconnect.49npgkeo",
      },
    },
    {
      id: "tece-waggawagga-substation",
      mapboxStyle: "mapbox://styles/energyconnect/ckycfbb7n0x9a15nukuf7cxtc",
      "source-layer": "WaggawWaggaWest-cw9zgv",
      type: "fill",
      source: {
        type: "vector",
        url: "mapbox://energyconnect.6kldd5n0",
      },
    },
    {
      id: "dinawan-substation-site",
      mapboxStyle: "mapbox://styles/energyconnect/ckykryihi3axd15o7w0totqwi",
      "source-layer": "MainConstructionCompoundsandA-cufffm",
      type: "fill",
      source: {
        type: "vector",
        url: "mapbox://energyconnect.az0utt6w",
      },
    },
    {
      id: "tece-terrestrial-gde",
      mapboxStyle: "mapbox://styles/energyconnect/ckwvok38f1b2o15oe94joa0r0",
      "source-layer": "Groundwater_TerrestrialGround-dfz8r0",
      type: "fill",
      source: {
        type: "vector",
        url: "mapbox://energyconnect.5npcof84",
      },
    },
    {
      id: "tece-landuse",
      mapboxStyle: "mapbox://styles/energyconnect/ckwvok38f1b2o15oe94joa0r0",
      "source-layer": "LandUse_LandUse_2017-9ck8cc",
      type: "fill",
      source: {
        type: "vector",
        url: "mapbox://energyconnect.dkye8z7j",
      },
    },
    {
      id: "tece-flood-depth",
      mapboxStyle: "mapbox://styles/energyconnect/ckwvok38f1b2o15oe94joa0r0",
      "source-layer": "FLoodingData_RTP_32bit-4pgn2k",
      type: "fill",
      source: {
        type: "vector",
        url: "mapbox://energyconnect.1jii40l5",
      },
    },
    {
      id: "tece-non-aboriginal-heritage-site",
      mapboxStyle: "mapbox://styles/energyconnect/ckwvok38f1b2o15oe94joa0r0",
      "source-layer": "NonAboriginalHeritageSites_po-2nbvl4",
      type: "circle",
      source: {
        type: "vector",
        url: "mapbox://energyconnect.3e5k87o1",
      },
    },
    {
      id: "tece-ibra-symbol",
      mapboxStyle: "mapbox://styles/energyconnect/ckwvok38f1b2o15oe94joa0r0",
      "source-layer": "IBRARegionsSubregions-32lnc3",
      type: "symbol",
      source: {
        type: "vector",
        url: "mapbox://energyconnect.bs5s854n",
      },
    },
    {
      id: "tece-threatened-fauna",
      mapboxStyle: "mapbox://styles/energyconnect/ckwvok38f1b2o15oe94joa0r0",
      "source-layer": "ThreatenedFauna-73lxvt",
      type: "symbol",
      source: {
        type: "vector",
        url: "mapbox://energyconnect.d5z5uy97",
      },
    },
    {
      id: "tece-threatened-flora",
      mapboxStyle: "mapbox://styles/energyconnect/ckwvok38f1b2o15oe94joa0r0",
      "source-layer": "ThreatenedFlora-1wasxl",
      type: "circle",
      source: {
        type: "vector",
        url: "mapbox://energyconnect.d2z14rjh",
      },
    },
    {
      id: "tece-ibra",
      mapboxStyle: "mapbox://styles/energyconnect/ckwvok38f1b2o15oe94joa0r0",
      "source-layer": "IBRARegionsSubregions-32lnc3",
      type: "line",
      source: {
        type: "vector",
        url: "mapbox://energyconnect.bs5s854n",
      },
    },
    {
      id: "tece-mitchell-landscape",
      mapboxStyle: "mapbox://styles/energyconnect/ckwvok38f1b2o15oe94joa0r0",
      "source-layer": "Mitchell_Landscape-4qvweg",
      type: "fill",
      source: {
        type: "vector",
        url: "mapbox://energyconnect.6gyi6w1s",
      },
    },
    {
      id: "tece-threatened-flora-poly",
      mapboxStyle: "mapbox://styles/energyconnect/ckwvok38f1b2o15oe94joa0r0",
      "source-layer": "ThreatenedFloraPoly-bagqjl",
      type: "fill",
      source: {
        type: "vector",
        url: "mapbox://energyconnect.cuzrf1sq",
      },
    },
    {
      id: "tece-fieldveg",
      mapboxStyle: "mapbox://styles/energyconnect/ckwvok38f1b2o15oe94joa0r0",
      "source-layer": "FieldVerifiedVeg-2avp8z",
      type: "fill",
      source: {
        type: "vector",
        url: "mapbox://energyconnect.7o5cyjq2",
      },
    },
    {
      id: "tece-non-aboriginal-heritage-site-text",
      mapboxStyle: "mapbox://styles/energyconnect/ckwvok38f1b2o15oe94joa0r0",
      "source-layer": "NonAboriginalHeritageSites_po-2nbvl4",
      type: "symbol",
      source: {
        type: "vector",
        url: "mapbox://energyconnect.3e5k87o1",
      },
    },
    {
      id: "tece-cadastre",
      mapboxStyle: "mapbox://styles/energyconnect/ckwvncr615ujd14s1ujh9nald",
      "source-layer": "Cadastre-1vlny2",
      type: "line",
      source: {
        type: "vector",
        url: "mapbox://energyconnect.cq8jb1mi",
      },
    },
    {
      id: "tece-west-construction",
      mapboxStyle: "mapbox://styles/energyconnect/ckycfbb7n0x9a15nukuf7cxtc",
      "source-layer": "camp-accom-construction-crh7pj",
      type: "fill",
      source: {
        type: "vector",
        url: "mapbox://energyconnect.9e2krgc9",
      },
    },
    {
      id: "tece-west-corridor",
      mapboxStyle: "mapbox://styles/energyconnect/ckycfbb7n0x9a15nukuf7cxtc",
      "source-layer": "trans-line-corr-8c9vkq",
      type: "line",
      source: {
        type: "vector",
        url: "mapbox://energyconnect.1qss45hk",
      },
    },
    {
      id: "alternate-satellite-imagery",
      type: "raster",
      source: {
        type: "raster",
        tiles: [
          "https://maps1.six.nsw.gov.au/arcgis/rest/services/sixmaps/LPI_Imagery_Best/MapServer/tile/{z}/{y}/{x}",
          "https://maps2.six.nsw.gov.au/arcgis/rest/services/sixmaps/LPI_Imagery_Best/MapServer/tile/{z}/{y}/{x}",
          "https://maps3.six.nsw.gov.au/arcgis/rest/services/sixmaps/LPI_Imagery_Best/MapServer/tile/{z}/{y}/{x}",
          "https://maps4.six.nsw.gov.au/arcgis/rest/services/sixmaps/LPI_Imagery_Best/MapServer/tile/{z}/{y}/{x}",
        ],
        tileSize: 256,
        minzoom: 4,
        maxzoom: 18,
        attribution: "© Department Finance, Services and Innovation",
      },
      paint: {
        "raster-opacity": 0.8,
      },
    },
  ],
  layerLegends: {
    "photo-points-viewpoint": [
      {
        legendType: "img",
        colour: "",
        text: "Existing viewpoint",
        value: "camera-viewpoint-legend",
      },
    ],
    "photo-points-photomontage": [
      {
        legendType: "img",
        colour: "",
        text: "Photomontages",
        value: "camera-photomontage-legend",
      },
    ],
    "tece-balranald-substation": [
      {
        legendType: "icon",
        colour: "#171065",
        text: "Balranald substation",
        value: "CircleFill",
      },
    ],
    "tece-buronga-substation": [
      {
        legendType: "icon",
        colour: "#844d59",
        text: "Buronga substation",
        value: "CircleFill",
      },
    ],
    "tece-optical-repeater": [
      {
        legendType: "icon",
        colour: "#000000",
        text: "Optical repeaters",
        value: "CircleFill",
      },
    ],
    "tece-town": [
      {
        legendType: "icon",
        colour: "#4e4e4e",
        text: "Towns",
        value: "CircleFill",
      },
    ],
    "tece-water-fill-points": [
      {
        legendType: "icon",
        colour: "#ca7af5",
        text: "Water fill points",
        value: "CircleFill",
      },
    ],
    "tece-sensitive-recievers": [
      {
        legendType: "icon",
        colour: "#ffaa00",
        text: "Sensitive receivers - Community facility",
        value: "CircleFill",
      },
      {
        legendType: "icon",
        colour: "#686868",
        text: "Sensitive receivers - Industry facility",
        value: "CircleFill",
      },
      {
        legendType: "icon",
        colour: "#f57a7a",
        text: "Sensitive receivers - Residential dwellings",
        value: "CircleFill",
      },
      {
        legendType: "icon",
        colour: "#e6e600",
        text: "Sensitive receivers - Utility facility",
        value: "CircleFill",
      },
      {
        legendType: "icon",
        colour: "#ca7af5",
        text: "Sensitive receivers - other",
        value: "CircleFill",
      },
    ],
    "tece-waterways": [
      {
        legendType: "line",
        colour: "#9ebbd7",
        text: "Waterways",
        value: "solid",
      },
    ],
    "tece-easement-330kv": [
      {
        legendType: "line",
        colour: "#277500",
        text: "Transmission line easement - 330kV",
        value: "solid",
      },
    ],
    "tece-easement-550kv": [
      {
        legendType: "line",
        colour: "#004da8",
        text: "Transmission line easement - 500kV",
        value: "solid",
      },
    ],
    "tece-existing-transmission-infrastructure": [
      {
        legendType: "line",
        colour: "#ffd280",
        text: "Existing transmission infrastructure",
        value: "solid",
      },
    ],
    "tece-travelling-stock-routes": [
      {
        legendType: "fill",
        colour: "#ec5f85",
        text: "Travelling stock routes",
        value: "solid",
      },
    ],
    "tece-state-forests": [
      {
        legendType: "fill",
        colour: "#89cd66",
        text: "State forests",
        value: "solid",
      },
    ],
    "tece-registered-gw-boreholes": [
      {
        legendType: "icon",
        colour: "rgba(0, 0, 0, 0)",
        text: "Registered boreholes",
        value: "Square",
      },
      {
        legendType: "icon",
        colour: "#343434",
        text: "Drainage - Groundwater",
        value: "CircleFill",
      },
      {
        legendType: "icon",
        colour: "#cdaa66",
        text: "Exploration / Mining Activities",
        value: "CircleFill",
      },
      {
        legendType: "icon",
        colour: "#ca7af5",
        text: "Household",
        value: "CircleFill",
      },
      {
        legendType: "icon",
        colour: "#ff5500",
        text: "Irrigated Agriculture",
        value: "CircleFill",
      },
      {
        legendType: "icon",
        colour: "#e6e600",
        text: "Mining / Mining Activities",
        value: "CircleFill",
      },
      {
        legendType: "icon",
        colour: "#ffaa00",
        text: "Monitoring",
        value: "CircleFill",
      },
      {
        legendType: "icon",
        colour: "#00e6a9",
        text: "Water Supply for Livestock",
        value: "CircleFill",
      },
      {
        legendType: "icon",
        colour: "#0070ff",
        text: "Water Supply",
        value: "CircleFill",
      },
      {
        legendType: "icon",
        colour: "#686868",
        text: "Unknown",
        value: "CircleFill",
      },
    ],
    "tece-operational-noise-transmission": [
      {
        legendType: "icon",
        colour: "rgba(0, 0, 0, 0)",
        text: "Operational noise transmission",
        value: "Square",
      },
      {
        legendType: "icon",
        colour: "#aaff00",
        text: "Negligible",
        value: "CircleFill",
      },
      {
        legendType: "icon",
        colour: "#ffaa00",
        text: "Moderate",
        value: "CircleFill",
      },
      {
        legendType: "icon",
        colour: "#ca7af5",
        text: "Significant",
        value: "CircleFill",
      },
    ],
    "tece-construction-noise-buffer-standard": [
      {
        legendType: "icon",
        colour: "rgba(0, 0, 0, 0)",
        text: "Construction noise buffer - standard",
        value: "Square",
      },
      {
        legendType: "icon",
        colour: "#aaff00",
        text: "6 dB - 10 dB",
        value: "CircleFill",
      },
      {
        legendType: "icon",
        colour: "#ffaa00",
        text: "11 dB - 20 dB",
        value: "CircleFill",
      },
      {
        legendType: "icon",
        colour: "#ca7af5",
        text: "21 dB - 30 dB",
        value: "CircleFill",
      },
      {
        legendType: "icon",
        colour: "#ca7af5",
        text: "31 dB - 40 dB",
        value: "CircleFill",
      },
    ],
    "tece-construction-noise-buffer-night": [
      {
        legendType: "icon",
        colour: "rgba(0, 0, 0, 0)",
        text: "Construction noise buffer - night",
        value: "Square",
      },
      {
        legendType: "icon",
        colour: "#D3FFBE",
        text: "<5 dB",
        value: "CircleFill",
      },
      {
        legendType: "icon",
        colour: "#aaff00",
        text: "6 dB - 10 dB",
        value: "CircleFill",
      },
      {
        legendType: "icon",
        colour: "#ffaa00",
        text: "11 dB - 20 dB",
        value: "CircleFill",
      },
      {
        legendType: "icon",
        colour: "#ca7af5",
        text: "21 dB - 30 dB",
        value: "CircleFill",
      },
      {
        legendType: "icon",
        colour: "#ca7af5",
        text: "31 dB - 40 dB",
        value: "CircleFill",
      },
    ],
    "tece-construction-noise-buffer-day": [
      {
        legendType: "icon",
        colour: "rgba(0, 0, 0, 0)",
        text: "Construction noise buffer - day",
        value: "Square",
      },
      {
        legendType: "icon",
        colour: "#D3FFBE",
        text: "<5 dB",
        value: "CircleFill",
      },
      {
        legendType: "icon",
        colour: "#aaff00",
        text: "6 dB - 10 dB",
        value: "CircleFill",
      },
      {
        legendType: "icon",
        colour: "#ffaa00",
        text: "11 dB - 20 dB",
        value: "CircleFill",
      },
      {
        legendType: "icon",
        colour: "#ca7af5",
        text: "21 dB - 30 dB",
        value: "CircleFill",
      },
      {
        legendType: "icon",
        colour: "#ca7af5",
        text: "31 dB - 40 dB",
        value: "CircleFill",
      },
    ],
    "tece-previously-recorded-ahims": [
      {
        legendType: "icon",
        colour: "#f5f57a",
        text: "Previously recorded Aboriginal Heritage site",
        value: "CircleFill",
      },
    ],
    "tece-new-recorded-ahims": [
      {
        legendType: "icon",
        colour: "#ffff00",
        text: "New recorded Aboriginal Heritage site",
        value: "CircleFill",
      },
    ],
    "tece-proposed-haulage-routes": [
      {
        legendType: "line",
        colour: "#343434",
        text: "Primary haulage route",
        value: "solid",
      },
      {
        legendType: "line",
        colour: "#002673",
        text: "Secondary haulage route",
        value: "solid",
      },
      {
        legendType: "line",
        colour: "#00c5ff",
        text: "Water haulage route",
        value: "solid",
      },
      {
        legendType: "line",
        colour: "#686868",
        text: "Collector haulage route",
        value: "solid",
      },
      {
        legendType: "line",
        colour: "#343434",
        text: "Local haulage route",
        value: "solid",
      },
    ],
    "tece-landuse-inherent-soil-fertility": [
      {
        legendType: "fill",
        colour: "#006100",
        text: "Low fertility",
        value: "solid",
      },
      {
        legendType: "fill",
        colour: "#7aab00",
        text: "Moderately low fertility",
        value: "solid",
      },
      {
        legendType: "fill",
        colour: "#ffff00",
        text: "Moderate fertility",
        value: "solid",
      },
    ],
    "tece-landuse-crown": [
      {
        legendType: "fill",
        colour: "#ffff73",
        text: "Crown land",
        value: "solid",
      },
    ],
    "tece-catchments": [
      {
        legendType: "icon",
        colour: "rgba(0, 0, 0, 0)",
        text: "Catchments",
        value: "Square",
      },
      {
        legendType: "fill",
        colour: "#ffaa00",
        text: "Darling",
        value: "solid",
      },
      {
        legendType: "fill",
        colour: "#66cdab",
        text: "Lake Victoria",
        value: "solid",
      },
      {
        legendType: "fill",
        colour: "#a3ff73",
        text: "Lachlan",
        value: "solid",
      },
      {
        legendType: "fill",
        colour: "#6699cd",
        text: "Peacock Creek",
        value: "solid",
      },
      {
        legendType: "fill",
        colour: "#edc26b",
        text: "Murrumbidgee",
        value: "solid",
      },
      {
        legendType: "fill",
        colour: "#ff5500",
        text: "Murray",
        value: "solid",
      },
    ],
    "tece-aquatic-gde": [
      {
        legendType: "icon",
        colour: "rgba(0, 0, 0, 0)",
        text: "Aquatic groundwater dependent ecosystems",
        value: "Square",
      },
      {
        legendType: "fill",
        colour: "#00734c",
        text: "High potential",
        value: "solid",
      },
      {
        legendType: "fill",
        colour: "#00e6a9",
        text: "Moderate potential",
        value: "solid",
      },
      {
        legendType: "fill",
        colour: "#d7d79e",
        text: "Low potential",
        value: "solid",
      },
    ],
    "tece-main-construction-compound": [
      {
        legendType: "fill",
        colour: "#ffaa00",
        text: "Main construction compound",
        value: "solid",
      },
    ],
    "tece-cia-updated": [
      {
        legendType: "line",
        colour: "#ecf330",
        text: "Construction impact area",
        value: "solid",
      },
    ],
    "tece-waggawagga-substation": [
      {
        legendType: "fill",
        colour: "#FF7F7F",
        text: "Wagga Wagga substation",
        value: "solid",
      },
    ],
    "dinawan-substation-site": [
      {
        legendType: "fill",
        colour: "#f524b9",
        text: "Dinawan substation site",
        value: "solid",
      },
    ],
    "tece-terrestrial-gde": [
      {
        legendType: "icon",
        colour: "rgba(0, 0, 0, 0)",
        text: "Terrestrial groundwater dependent ecosystems",
        value: "Square",
      },
      {
        legendType: "fill",
        colour: "#e60000",
        text: "High potential",
        value: "solid",
      },
      {
        legendType: "fill",
        colour: "#e69800",
        text: "Moderate potential",
        value: "solid",
      },
      {
        legendType: "fill",
        colour: "#ffebaf",
        text: "Low potential",
        value: "solid",
      },
    ],
    "tece-landuse": [
      {
        legendType: "icon",
        colour: "rgba(0, 0, 0, 0)",
        text: "Land use",
        value: "Square",
      },
      {
        legendType: "fill",
        colour: "#89cd66",
        text: "1.1.0 Nature conservation",
        value: "solid",
      },
      {
        legendType: "fill",
        colour: "#e60000",
        text: "1.2.0 Managed resource protection",
        value: "solid",
      },
      {
        legendType: "fill",
        colour: "#e1e1e1",
        text: "1.3.0 Other minimal use",
        value: "solid",
      },
      {
        legendType: "fill",
        colour: "#d3ffbe",
        text: "2.1.0 Grazing native vegetation",
        value: "solid",
      },
      {
        legendType: "fill",
        colour: "#edc26b",
        text: "2.2.0 Production native forestry",
        value: "solid",
      },
      {
        legendType: "fill",
        colour: "#267300",
        text: "3.1.0 Plantation forests",
        value: "solid",
      },
      {
        legendType: "fill",
        colour: "#4ce600",
        text: "3.2.0 Grazing modified pastures",
        value: "solid",
      },
      {
        legendType: "fill",
        colour: "#ffebbe",
        text: "3.3.0 Cropping",
        value: "solid",
      },
      {
        legendType: "fill",
        colour: "#d14f61",
        text: "3.4.0 Perennial horticulture",
        value: "solid",
      },
      {
        legendType: "fill",
        colour: "#d8ed9d",
        text: "3.5.0 Seasonal horticulture",
        value: "solid",
      },
      {
        legendType: "fill",
        colour: "#378c89",
        text: "3.6.0 Land in transition",
        value: "solid",
      },
      {
        legendType: "fill",
        colour: "#7e52c4",
        text: "4.1.0 Irrigated plantation forests",
        value: "solid",
      },
      {
        legendType: "fill",
        colour: "#7ead47",
        text: "4.2.0 Grazing irrigated modified pastures",
        value: "solid",
      },
      {
        legendType: "fill",
        colour: "#c29ed7",
        text: "4.3.0 Irrigated cropping",
        value: "solid",
      },
      {
        legendType: "fill",
        colour: "#7f8556",
        text: "4.4.0 Irrigated perennial horticulture",
        value: "solid",
      },
      {
        legendType: "fill",
        colour: "#e851a4",
        text: "4.5.0 Irrigated seasonal horticulture",
        value: "solid",
      },
      {
        legendType: "fill",
        colour: "#6e64a1",
        text: "4.6.0 Irrigated land in transition",
        value: "solid",
      },
      {
        legendType: "fill",
        colour: "#5b84c2",
        text: "5.1.0 Intensive horticulture",
        value: "solid",
      },
      {
        legendType: "fill",
        colour: "#b28878",
        text: "5.2.0 Intensive animal production",
        value: "solid",
      },
      {
        legendType: "fill",
        colour: "#66cdab",
        text: "5.3.0 Manufacturing and industrial",
        value: "solid",
      },
      {
        legendType: "fill",
        colour: "#5ca16d",
        text: "5.4.0 Residential and farm infrastructure",
        value: "solid",
      },
      {
        legendType: "fill",
        colour: "#ff5500",
        text: "5.5.0 Services",
        value: "solid",
      },
      {
        legendType: "fill",
        colour: "#9c4e65",
        text: "5.6.0 Utilities",
        value: "solid",
      },
      {
        legendType: "fill",
        colour: "#e6e600",
        text: "5.7.0 Transport and communication",
        value: "solid",
      },
      {
        legendType: "fill",
        colour: "#ffaa00",
        text: "5.8.0 Mining",
        value: "solid",
      },
      {
        legendType: "fill",
        colour: "#8fedf2",
        text: "5.9.0 Waste treatment and disposal",
        value: "solid",
      },
      {
        legendType: "fill",
        colour: "#0070ff",
        text: "6.1.0 Lake",
        value: "solid",
      },
      {
        legendType: "fill",
        colour: "#73b2ff",
        text: "6.2.0 Reservoir/dam",
        value: "solid",
      },
      {
        legendType: "fill",
        colour: "#00c5ff",
        text: "6.3.0 River",
        value: "solid",
      },
      {
        legendType: "fill",
        colour: "#00a9e6",
        text: "6.4.0 Channel/aqueduct",
        value: "solid",
      },
      {
        legendType: "fill",
        colour: "#66cdab",
        text: "6.5.0 Marsh/wetland",
        value: "solid",
      },
    ],
    "tece-non-aboriginal-heritage-site": [
      {
        legendType: "icon",
        colour: "#7f0a0a",
        text: "Non-aboriginal heritage sites",
        value: "CircleFill",
      },
    ],
    "tece-flood-depth": [
      {
        legendType: "icon",
        colour: "rgba(0, 0, 0, 0)",
        text: "Peak flood depth",
        value: "Square",
      },
      {
        legendType: "fill",
        colour: "#ffbee8",
        text: "1m - 5m",
        value: "solid",
      },
      {
        legendType: "fill",
        colour: "#ff73df",
        text: "5m - 10m",
        value: "solid",
      },
      {
        legendType: "fill",
        colour: "#e600a9",
        text: "10m - 20m",
        value: "solid",
      },
      {
        legendType: "fill",
        colour: "#ff5500",
        text: "20m - 30m",
        value: "solid",
      },
      {
        legendType: "fill",
        colour: "#ff0000",
        text: "> 30m",
        value: "solid",
      },
    ],
    "tece-ibra": [
      {
        legendType: "line",
        colour: "#a80000",
        text: "IBRA regions and subregions",
        value: "dashed",
      },
    ],
    "tece-threatened-flora": [
      {
        legendType: "icon",
        colour: "#ff7f7f",
        text: "Brachyscome papillosa",
        value: "CircleFill",
      },
      {
        legendType: "icon",
        colour: "#ffffbe",
        text: "Leptorhynchos orientalis",
        value: "CircleFill",
      },
      {
        legendType: "icon",
        colour: "#aa66cd",
        text: "Maeriana cheelii",
        value: "CircleFill",
      },
      {
        legendType: "icon",
        colour: "#cdaa66",
        text: "Pilularia novae-hollandiae",
        value: "CircleFill",
      },
      {
        legendType: "icon",
        colour: "#d3ffbe",
        text: "Pimelea serpyllifolia subsp. serpyllifolia",
        value: "CircleFill",
      },
      {
        legendType: "icon",
        colour: "#ff5500",
        text: "Santalum murrayanum",
        value: "CircleFill",
      },
      {
        legendType: "icon",
        colour: "#beffe8",
        text: "Swainsona murrayana",
        value: "CircleFill",
      },
      {
        legendType: "icon",
        colour: "#0070ff",
        text: "Swainsona sericea",
        value: "CircleFill",
      },
    ],
    "tece-threatened-flora-poly": [
      {
        legendType: "fill",
        colour: "#cc4e47",
        text: "Brachyscome papillosa",
        value: "solid",
      },
      {
        legendType: "fill",
        colour: "#f7f063",
        text: "Leptorhynchos orientalis",
        value: "solid",
      },
      {
        legendType: "fill",
        colour: "#803366",
        text: "Maeriana cheelii",
        value: "solid",
      },
      {
        legendType: "fill",
        colour: "#967950",
        text: "Pilularia novae-hollandiae",
        value: "solid",
      },
      {
        legendType: "fill",
        colour: "#75f573",
        text: "Pimelea serpyllifolia subsp. serpyllifolia",
        value: "solid",
      },
      {
        legendType: "fill",
        colour: "#93faf8",
        text: "Swainsona murrayana",
        value: "solid",
      },
      {
        legendType: "fill",
        colour: "#0070ff",
        text: "Swainsona sericea",
        value: "solid",
      },
      {
        legendType: "fill",
        colour: "#c896ca",
        text: "Santanum murrayanum",
        value: "solid",
      },
      {
        legendType: "fill",
        colour: "#25701f",
        text: "Lepidium monoplocoides",
        value: "solid",
      },
    ],
    "tece-threatened-fauna": [
      {
        legendType: "icon",
        colour: "#AAFF00",
        text: "Black Falcon - Falco subniger",
        value: "StarFill",
      },
      {
        legendType: "icon",
        colour: "#F5F57A",
        text: "Bolam's Mouse - Pseudomys bolami",
        value: "StarFill",
      },
      {
        legendType: "icon",
        colour: "#0070FF",
        text: "Chestnut Quail-thrush - Cinclosoma castanotum",
        value: "StarFill",
      },
      {
        legendType: "icon",
        colour: "#FFAA00",
        text: "Dusky Woodswallow - Artamus cyanopterus",
        value: "StarFill",
      },
      {
        legendType: "icon",
        colour: "#FF0000",
        text: "Gilbert's Whistler - Pachycephala inornata",
        value: "StarFill",
      },
      {
        legendType: "icon",
        colour: "#AAFF00",
        text: "Grey-crowned Babbler - Pomatostomus temporalis",
        value: "DiamondFill",
      },
      {
        legendType: "icon",
        colour: "#F5F57A",
        text: "Inland Forest Bat - Vespadelus baverstocki",
        value: "DiamondFill",
      },
      {
        legendType: "icon",
        colour: "#0070FF",
        text: "Little Eagle - Hieraaetus morphnoides",
        value: "DiamondFill",
      },
      {
        legendType: "icon",
        colour: "#FFAA00",
        text: "Major Mitchell's Cockatoo - Lophochroa leadbeateri",
        value: "DiamondFill",
      },
      {
        legendType: "icon",
        colour: "#FF0000",
        text: "Mallee Worm-lizard - Aprasia inaurita",
        value: "DiamondFill",
      },
      {
        legendType: "icon",
        colour: "#AAFF00",
        text: "Purple-crowned Lorikeet - Polytelis anthopeplus",
        value: "CircleFill",
      },
      {
        legendType: "icon",
        colour: "#F5F57A",
        text: "Rainbow Bee-eater - Merops ornatus",
        value: "CircleFill",
      },
      {
        legendType: "icon",
        colour: "#0070FF",
        text: "Regent Parrot - Polytelis anthopeplus",
        value: "CircleFill",
      },
      {
        legendType: "icon",
        colour: "#FFAA00",
        text: "South-east Long-eared Bat - Nyctophilus corbeni",
        value: "CircleFill",
      },
      {
        legendType: "icon",
        colour: "#FF0000",
        text: "Southern Myotis - Myotis macropus",
        value: "CircleFill",
      },
      {
        legendType: "icon",
        colour: "#AAFF00",
        text: "Spotted Harrier - Circus assimilis",
        value: "Plus",
      },
      {
        legendType: "icon",
        colour: "#F5F57A",
        text: "Squirrel Glider - Petaurus norfolcensis",
        value: "Plus",
      },
      {
        legendType: "icon",
        colour: "#0070FF",
        text: "Superb Parrot - Polytelis swainsonii",
        value: "Plus",
      },
      {
        legendType: "icon",
        colour: "#FFAA00",
        text: "White-fronted Chat - Epthianura albifrons",
        value: "Plus",
      },
    ],
    "tece-mitchell-landscape": [
      {
        legendType: "fill",
        colour: "#ffbee8",
        text: "Lower Darling Alluvial Plains",
        value: "solid",
      },
      {
        legendType: "fill",
        colour: "#f5f57a",
        text: "Lower Darling Channels and Floodplains",
        value: "solid",
      },
      {
        legendType: "fill",
        colour: "#ff7f7f",
        text: "Mallee Cliffs Dunes",
        value: "solid",
      },
      {
        legendType: "fill",
        colour: "#898944",
        text: "Mallee Cliffs Linear Dunes",
        value: "solid",
      },
      {
        legendType: "fill",
        colour: "#e69800",
        text: "Mallee Cliffs Sandplains",
        value: "solid",
      },
      {
        legendType: "fill",
        colour: "#66cdab",
        text: "Murray Channels and Floodplains",
        value: "solid",
      },
      {
        legendType: "fill",
        colour: "#aa66cd",
        text: "Murray Lakes, Swamps and Lunettes",
        value: "solid",
      },
      {
        legendType: "fill",
        colour: "#f5ca7a",
        text: "Scotia Groundwater Basins",
        value: "solid",
      },
      {
        legendType: "fill",
        colour: "#895a44",
        text: "Scotia Linear Dunes",
        value: "solid",
      },
      {
        legendType: "fill",
        colour: "#89cd66",
        text: "Scotia Sandplains",
        value: "solid",
      },
      {
        legendType: "fill",
        colour: "#cedcf2",
        text: "Other landscapes",
        value: "solid",
      },
    ],
    "tece-fieldveg": [
      {
        legendType: "fill",
        colour: "#89cd66",
        text: "Field verified vegetation",
        value: "solid",
      },
    ],
    "tece-cadastre": [
      {
        legendType: "line",
        colour: "#000000",
        text: "Cadastral boundaries",
        value: "solid",
      },
    ],
    "tece-west-construction": [
      {
        legendType: "fill",
        colour: "#ea3434",
        text: "Transmission line corridor (West)",
        value: "solid",
      },
    ],
    "tece-west-corridor": [
      {
        legendType: "line",
        colour: "#ea3434",
        text: "Proposed substation upgrade and expansion area (West)",
        value: "solid",
      },
    ],
  },
  mapComponentConfig: {
    moduleComponentName: "Mapbox",
    mapboxStyle: "mapbox://styles/energyconnect/ckgg57uuz5zlr19nxv2rtkk14",
    customAttribution: "Indicative only, subject to design development",
    initialMapState: {
      locationOptions: {
        zoom: 7,
        center: [144.7925285, -34.6812475],
        pitch: 0,
        bearing: 0,
      },
      resetInitialLocation: false,
      layerOptions: {
        visible: [
          "tece-easement-330kv",
          "tece-easement-550kv",
          "tece-buronga-substation",
          "tece-balranald-substation",
          "dinawan-substation-site",
          "tece-waggawagga-substation",
        ],
      },
    },
    basemaps: {
      primary: {
        imageName: "primary",
        type: "style",
        id: "mapbox://styles/energyconnect/ckgg57uuz5zlr19nxv2rtkk14",
      },
      alternate: {
        imageName: "alternate",
        type: "layer",
        id: "alternate-satellite-imagery",
      },
    },
  },
  popups: [
    {
      id: "photo-popup-viewpoint",
      layerId: "photo-points-viewpoint",
      mouseEvent: "click",
      featureProps: [
        {
          featureId: "1",
          moduleComponentName: "Thumbnail",
          moduleProps: {
            columnProps: { className: "p-0" },
            startingLightboxIndex: 0,
            isOpaqueUntilHover: false,
            fileName: "Viewpoint_1_IMG_6982",
            lightboxSources: [
              {
                type: "image",
                title: "Arumpo Road to the Buronga Terminal Substation",
                caption: "Arumpo Road to the Buronga Terminal Substation",
                fileName: "Viewpoint_1_IMG_6982",
              },
            ],
          },
        },
        {
          featureId: "3",
          moduleComponentName: "Thumbnail",
          moduleProps: {
            columnProps: { className: "p-0" },
            startingLightboxIndex: 0,
            isOpaqueUntilHover: false,
            fileName: "Viewpoint_3_IMG_7079",
            lightboxSources: [
              {
                type: "image",
                title: "View north west from Benanee Road",
                caption: "View north west from Benanee Road",
                fileName: "Viewpoint_3_IMG_7079",
              },
            ],
          },
        },
        {
          featureId: "5",
          moduleComponentName: "Thumbnail",
          moduleProps: {
            columnProps: { className: "p-0" },
            startingLightboxIndex: 0,
            isOpaqueUntilHover: false,
            fileName: "Viewpoint_5_IMG_7154",
            lightboxSources: [
              {
                type: "image",
                title: "View south from Yanga Way to alignment crossing",
                caption: "View south from Yanga Way to alignment crossing",
                fileName: "Viewpoint_5_IMG_7154",
              },
            ],
          },
        },
        {
          featureId: "6",
          moduleComponentName: "Thumbnail",
          moduleProps: {
            columnProps: { className: "p-0" },
            startingLightboxIndex: 0,
            isOpaqueUntilHover: false,
            fileName: "Viewpoint_6_IMG_7176",
            lightboxSources: [
              {
                type: "image",
                title: "View south from Balranald Road to alignment crossing",
                caption: "View south from Balranald Road to alignment crossing",
                fileName: "Viewpoint_6_IMG_7176",
              },
            ],
          },
        },
        {
          featureId: "8",
          moduleComponentName: "Thumbnail",
          moduleProps: {
            columnProps: { className: "p-0" },
            startingLightboxIndex: 0,
            isOpaqueUntilHover: false,
            fileName: "Viewpoint_8_IMG_7244",
            lightboxSources: [
              {
                type: "image",
                title:
                  "View east from Conargo Road / North Boundary Road junction",
                caption:
                  "View east from Conargo Road / North Boundary Road junction",
                fileName: "Viewpoint_8_IMG_7244",
              },
            ],
          },
        },
        {
          featureId: "9",
          moduleComponentName: "Thumbnail",
          moduleProps: {
            columnProps: { className: "p-0" },
            startingLightboxIndex: 0,
            isOpaqueUntilHover: false,
            fileName: "Viewpoint_9_IMG_7289",
            lightboxSources: [
              {
                type: "image",
                title: "View east along four Corners Road",
                caption: "View east along four Corners Road",
                fileName: "Viewpoint_9_IMG_7289",
              },
            ],
          },
        },
        {
          featureId: "17",
          moduleComponentName: "Thumbnail",
          moduleProps: {
            columnProps: { className: "p-0" },
            startingLightboxIndex: 0,
            isOpaqueUntilHover: false,
            fileName: "Viewpoint_17_IMG_7908",
            lightboxSources: [
              {
                type: "image",
                title: "View northeast from Holbrook Road",
                caption: "View northeast from Holbrook Road",
                fileName: "Viewpoint_17_IMG_7908",
              },
            ],
          },
        },
        {
          featureId: "18",
          moduleComponentName: "Thumbnail",
          moduleProps: {
            columnProps: { className: "p-0" },
            startingLightboxIndex: 0,
            isOpaqueUntilHover: false,
            fileName: "Viewpoint_18_IMG_7809",
            lightboxSources: [
              {
                type: "image",
                title: "View east from Holbrook Road",
                caption: "View east from Holbrook Road",
                fileName: "Viewpoint_18_IMG_7809",
              },
            ],
          },
        },
        {
          featureId: "20",
          moduleComponentName: "Thumbnail",
          moduleProps: {
            columnProps: { className: "p-0" },
            startingLightboxIndex: 0,
            isOpaqueUntilHover: false,
            fileName: "Viewpoint_20_IMG_7886",
            lightboxSources: [
              {
                type: "image",
                title: "View southwest from Butterbush Road",
                caption: "View southwest from Butterbush Road",
                fileName: "Viewpoint_20_IMG_7886",
              },
            ],
          },
        },
      ],
    },
    {
      id: "photo-popup-photomontage",
      layerId: "photo-points-photomontage",
      mouseEvent: "click",
      featureProps: [
        {
          featureId: "2",
          moduleComponentName: "Thumbnail",
          moduleProps: {
            columnProps: { className: "p-0" },
            startingLightboxIndex: 0,
            isOpaqueUntilHover: false,
            fileName: "Photomontage_Viewpoint_2_330kV",
            lightboxSources: [
              {
                type: "image",
                title: "View east from Sturt Highway, near Lake Benanee",
                caption:
                  "View east from Sturt Highway, near Lake Benanee - indicative only",
                fileName: "Photomontage_Viewpoint_2_330kV",
              },
            ],
          },
        },
        {
          featureId: "4",
          moduleComponentName: "Thumbnail",
          moduleProps: {
            columnProps: { className: "p-0" },
            startingLightboxIndex: 0,
            isOpaqueUntilHover: false,
            fileName: "Photomontage_Viewpoint_4_330kV",
            lightboxSources: [
              {
                type: "image",
                title: "View east from Stuart Highway",
                caption: "View east from Stuart Highway - indicative only",
                fileName: "Photomontage_Viewpoint_4_330kV",
              },
            ],
          },
        },
        {
          featureId: "7",
          moduleComponentName: "Thumbnail",
          moduleProps: {
            columnProps: { className: "p-0" },
            startingLightboxIndex: 0,
            isOpaqueUntilHover: false,
            fileName: "Photomontage_Viewpoint_7_330kV",
            lightboxSources: [
              {
                type: "image",
                title: "View south from Cobb Highway",
                caption: "View south from Cobb Highway - indicative only",
                fileName: "Photomontage_Viewpoint_7_330kV",
              },
            ],
          },
        },
        {
          featureId: "10",
          moduleComponentName: "Thumbnail",
          moduleProps: {
            columnProps: { className: "p-0" },
            startingLightboxIndex: 0,
            isOpaqueUntilHover: false,
            fileName: "Photomontage_Viewpoint_10_500kV_revB",
            lightboxSources: [
              {
                type: "image",
                title: "View TBC along Kidman Way to proposed substation",
                caption:
                  "View TBC along Kidman Way to proposed substation - indicative only",
                fileName: "Photomontage_Viewpoint_10_500kV_revB",
              },
            ],
          },
        },
        {
          featureId: "11",
          moduleComponentName: "Thumbnail",
          moduleProps: {
            columnProps: { className: "p-0" },
            startingLightboxIndex: 0,
            isOpaqueUntilHover: false,
            fileName: "Photomontage_Viewpoint_11_500kV_revB",
            lightboxSources: [
              {
                type: "image",
                title:
                  "View Northeast along Newall Highway to alignment crossing",
                caption:
                  "View Northeast along Newall Highway to alignment crossing - indicative only",
                fileName: "Photomontage_Viewpoint_11_500kV_revB",
              },
            ],
          },
        },
        {
          featureId: "12",
          moduleComponentName: "Thumbnail",
          moduleProps: {
            columnProps: { className: "p-0" },
            startingLightboxIndex: 0,
            isOpaqueUntilHover: false,
            fileName: "Photomontage_Viewpoint_12_500kV",
            lightboxSources: [
              {
                type: "image",
                title: "View west of Lockhart",
                caption: "View west of Lockhart - indicative only",
                fileName: "Photomontage_Viewpoint_12_500kV",
              },
            ],
          },
        },
        {
          featureId: "13",
          moduleComponentName: "Thumbnail",
          moduleProps: {
            columnProps: { className: "p-0" },
            startingLightboxIndex: 0,
            isOpaqueUntilHover: false,
            fileName: "Photomontage_Viewpoint_13_500kV_revB",
            lightboxSources: [
              {
                type: "image",
                title: "View south from Lockart",
                caption: "View south from Lockart - indicative only",
                fileName: "Photomontage_Viewpoint_13_500kV_revB",
              },
            ],
          },
        },
        {
          featureId: "14",
          moduleComponentName: "Thumbnail",
          moduleProps: {
            columnProps: { className: "p-0" },
            startingLightboxIndex: 0,
            isOpaqueUntilHover: false,
            fileName: "Photomontage_Viewpoint_14_500kV_revB",
            lightboxSources: [
              {
                type: "image",
                title: "View west from Lockhart",
                caption: "View west from Lockhart - indicative only",
                fileName: "Photomontage_Viewpoint_14_500kV_revB",
              },
            ],
          },
        },
        {
          featureId: "15",
          moduleComponentName: "Thumbnail",
          moduleProps: {
            columnProps: { className: "p-0" },
            startingLightboxIndex: 0,
            isOpaqueUntilHover: false,
            fileName: "Photomontage_Viewpoint_15_500kV_revB",
            lightboxSources: [
              {
                type: "image",
                title: "View southeast along The Rock-Bullenbong Road",
                caption:
                  "View southeast along The Rock-Bullenbong Road - indicative only",
                fileName: "Photomontage_Viewpoint_15_500kV_revB",
              },
            ],
          },
        },
        {
          featureId: "16",
          moduleComponentName: "Thumbnail",
          moduleProps: {
            columnProps: { className: "p-0" },
            startingLightboxIndex: 0,
            isOpaqueUntilHover: false,
            fileName: "Photomontage_Viewpoint_16_500kV_revB",
            lightboxSources: [
              {
                type: "image",
                title: "View northeast from Olympic Highway",
                caption:
                  "View northeast from Olympic Highway - indicative only",
                fileName: "Photomontage_Viewpoint_16_500kV_revB",
              },
            ],
          },
        },
        {
          featureId: "19",
          moduleComponentName: "Thumbnail",
          moduleProps: {
            columnProps: { className: "p-0" },
            startingLightboxIndex: 0,
            isOpaqueUntilHover: false,
            fileName: "Photomontage_Viewpoint_19_500kV_revB",
            lightboxSources: [
              {
                type: "image",
                title: "View south east from Rowan Road",
                caption: "View south east from Rowan Road - indicative only",
                fileName: "Photomontage_Viewpoint_19_500kV_revB",
              },
            ],
          },
        },
      ],
    },
  ],
  sites: [
    {
      id: "easement330kv",
      label: "330kV transmission line easement",
      locationOptions: {
        zoom: 8,
        center: [143.976, -34.701],
        pitch: 0,
        bearing: 0,
      },
      layerOptions: [],
    },
    {
      id: "easement550kv",
      label: "500kV transmission line easement",
      locationOptions: {
        zoom: 9,
        center: [146.622, -35.225],
        pitch: 0,
        bearing: 0,
      },
      layerOptions: [],
    },
    {
      id: "burongaSubstation",
      label: "Buronga construction compound and accommodation camp",
      locationOptions: {
        zoom: 15,
        center: [142.258419, -34.102136],
        pitch: 0,
        bearing: 0,
      },
      layerOptions: [{ id: "tece-main-construction-compound", visible: true }],
    },
    {
      id: "balranaldSubstation",
      label:
        "Balranald construction compound and accommodation camp (location options)",
      locationOptions: {
        zoom: 11,
        center: [143.535828, -34.6957106],
        pitch: 0,
        bearing: 0,
      },
      layerOptions: [{ id: "tece-main-construction-compound", visible: true }],
    },
    {
      id: "cobbhighway",
      label: "Cobb Highway construction compound and accommodation camp",
      locationOptions: {
        zoom: 15,
        center: [144.762856, -34.8507882],
        pitch: 0,
        bearing: 0,
      },
      layerOptions: [{ id: "tece-main-construction-compound", visible: true }],
    },
    {
      id: "dinawanSubstation",
      label: "Dinawan construction compound and accommodation camp",
      locationOptions: {
        zoom: 12,
        center: [145.821, -35.056],
        pitch: 0,
        bearing: 0,
      },
      layerOptions: [{ id: "tece-main-construction-compound", visible: true }],
    },
    {
      id: "dinawanSubstationpt",
      label: "Dinawan substation",
      locationOptions: {
        zoom: 12,
        center: [145.821, -35.056],
        pitch: 0,
        bearing: 0,
      },
      layerOptions: [{ id: "dinawan-substation-site", visible: true }],
    },
    {
      id: "lockhart",
      label:
        "Lockhart construction compound and accommodation camp (location options)",
      locationOptions: {
        zoom: 10,
        center: [146.7234059, -35.2222526],
        pitch: 0,
        bearing: 0,
      },
      layerOptions: [{ id: "tece-main-construction-compound", visible: true }],
    },
    {
      id: "waggawaggaStnUpgrade",
      label: "Wagga Wagga construction compound",
      locationOptions: {
        zoom: 13,
        center: [147.405, -35.2],
        pitch: 0,
        bearing: 0,
      },
      layerOptions: [{ id: "tece-main-construction-compound", visible: true }],
    },
    {
      id: "waggawaggaStn",
      label: "Wagga Wagga substation upgrade and expansion area",
      locationOptions: {
        zoom: 13,
        center: [147.3936244, -35.2006458],
        pitch: 0,
        bearing: 0,
      },
      layerOptions: [{ id: "tece-waggawagga-substation", visible: true }],
    },
    {
      id: "balranaldopticalrepeater",
      label: "Balranald optical repeater site",
      locationOptions: {
        zoom: 12,
        center: [143.5252201, -34.7749923],
        pitch: 0,
        bearing: 0,
      },
      layerOptions: [
        { id: "tece-optical-repeater", visible: true },
        { id: "tece-optical-repeater-label", visible: true },
      ],
    },
    {
      id: "booroobanopticalrepeater",
      label: "Boorooban optical repeater site",
      locationOptions: {
        zoom: 12,
        center: [144.5582332, -34.8774283],
        pitch: 0,
        bearing: 0,
      },
      layerOptions: [
        { id: "tece-optical-repeater", visible: true },
        { id: "tece-optical-repeater-label", visible: true },
      ],
    },
    {
      id: "uranalockhartopticalrepeater",
      label: "Urana-Lockhart optical repeater site",
      locationOptions: {
        zoom: 12,
        center: [146.6112415, -35.2641869],
        pitch: 0,
        bearing: 0,
      },
      layerOptions: [
        { id: "tece-optical-repeater", visible: true },
        { id: "tece-optical-repeater-label", visible: true },
      ],
    },
  ],
};

export const INDEX_PATH = "/";
export const CREATE_BASE_PATH = "/create";

export const navConfig = {
  [INDEX_PATH]: {
    hideUntilScroll: false,
    items: {
      nonCollapsing: [
        {
          item: "logo",
          filename: "transgridColour",
          linkProps: {
            path: "https://transgrid.com.au/energyconnect",
            isExternal: true,
          },
        },
      ],
      collapsing: [
        {
          item: "modal-button",
          text: "Make a Submission",
          modalId: "make-a-submission",
        },
      ],
    },
  },
};

export const footerConfig = {
  leftContent: {
    copyrightText: "© 2021 WSP",
  },
};
