/**
 * A Bootstrap modal whos content and configuration is defined in MDX templates.
 *
 * Templates must be added in `src/templates/modals`. These are pre-loaded into
 * the Redux store during the build step. This component expects to find a template
 * object in the store with the matching ID as the active modal ID.
 */
import React from "react";

import { MDXRenderer } from "gatsby-plugin-mdx";
import _isEmpty from "lodash/isEmpty";
import Modal from "react-bootstrap/Modal";
import { useSelector } from "react-redux";

import withModalTrigger from "./ModalTrigger";

const MdxModal = withModalTrigger((props) => {
  const activeModalTemplate = useSelector(
    (state) => state.modals.templates[props.activeModalId]
  );

  if (_isEmpty(activeModalTemplate)) {
    return <></>;
  }

  const { body, config } = activeModalTemplate;

  return (
    <Modal
      show={!!props.activeModalId}
      onHide={props.setActiveModal}
      centered={config.centered}
      dialogClassName={config.dialogClassName}
      scrollable={config.scrollable}
      size={config.size}
    >
      <MDXRenderer>{body}</MDXRenderer>
    </Modal>
  );
});

// The `setActiveModal` function sets the active modal to `null` as the
// `MdxModal` is in charge of closing the active modal.
MdxModal.defaultProps = {
  targetModalId: null,
};

export default MdxModal;
