export const photomontage = {
  type: "FeatureCollection",
  name: "montages",
  features: [
    {
      type: "Feature",
      geometry: {
        type: "Point",
        coordinates: [142.836966667000013, -34.513611111000003],
      },
      properties: {
        Name: "Viewpoint 2: View east from Sturt Highway, near Lake Benanee",
        id: "2",
      },
    },
    {
      type: "Feature",
      geometry: {
        type: "Point",
        coordinates: [143.306700000000006, -34.610783333000001],
      },
      properties: {
        Name: "Viewpoint 4: View east from Stuart Highway",
        id: "4",
      },
    },
    {
      type: "Feature",
      geometry: {
        type: "Point",
        coordinates: [144.776763888999994, -34.802594444],
      },
      properties: {
        Name: "Viewpoint 7: View south from Cobb Highway",
        id: "7",
      },
    },
    {
      type: "Feature",
      geometry: {
        type: "Point",
        coordinates: [145.792688889000004, -35.053055555999997],
      },
      properties: {
        Name: "Viewpoint 10: View TBC along Kidman Way to proposed substation",
        id: "10",
      },
    },
    {
      type: "Feature",
      geometry: {
        type: "Point",
        coordinates: [146.080338888999989, -35.119372222000003],
      },
      properties: {
        Name:
          "Viewpoint 11: View Northeast along Newall Highway to alignment crossing",
        id: "11",
      },
    },
    {
      type: "Feature",
      geometry: {
        type: "Point",
        coordinates: [146.677788888999999, -35.238472221999999],
      },
      properties: {
        Name: "Viewpoint 12: View west of Lockhart",
        id: "12",
      },
    },
    {
      type: "Feature",
      geometry: {
        type: "Point",
        coordinates: [146.722869443999997, -35.237980555999997],
      },
      properties: {
        Name: "Viewpoint 13: View south from Lockart",
        id: "13",
      },
    },
    {
      type: "Feature",
      geometry: {
        type: "Point",
        coordinates: [146.738841667000003, -35.23115],
      },
      properties: {
        Name: "Viewpoint 14: View west from Lockhart",
        id: "14",
      },
    },
    {
      type: "Feature",
      geometry: {
        type: "Point",
        coordinates: [147.038905556000003, -35.209052778],
      },
      properties: {
        Name: "Viewpoint 15: View southeast along The Rock-Bullenbong Road",
        id: "15",
      },
    },
    {
      type: "Feature",
      geometry: {
        type: "Point",
        coordinates: [147.212361111000007, -35.215336110999999],
      },
      properties: {
        Name: "Viewpoint 16: View northeast from Olympic Highway",
        id: "16",
      },
    },
    {
      type: "Feature",
      geometry: {
        type: "Point",
        coordinates: [147.346888629999995, -35.196948298000002],
      },
      properties: {
        Name: "Viewpoint 19: View south east from Rowan Road",
        id: "19",
      },
    },
  ],
};

export const viewpoints = {
  type: "FeatureCollection",
  name: "viewpoints",
  features: [
    {
      type: "Feature",
      geometry: {
        type: "Point",
        coordinates: [142.26562222199999, -34.100111110999997],
      },
      properties: {
        Name: "Viewpoint 1: Arumpo Road to the Buronga Terminal Substation",
        id: "1",
      },
    },
    {
      type: "Feature",
      geometry: {
        type: "Point",
        coordinates: [142.878333333, -34.512863889000002],
      },
      properties: {
        Name: "Viewpoint 3: View north west from Benanee Road",
        id: "3",
      },
    },
    {
      type: "Feature",
      geometry: {
        type: "Point",
        coordinates: [143.531977777999998, -34.772363888999998],
      },
      properties: {
        Name: "Viewpoint 5: View south from Yanga Way to alignment crossing",
        id: "5",
      },
    },
    {
      type: "Feature",
      geometry: {
        type: "Point",
        coordinates: [143.563077778000007, -34.795705556000001],
      },
      properties: {
        Name:
          "Viewpoint 6: View south from Balranald Road to alignment crossing",
        id: "6",
      },
    },
    {
      type: "Feature",
      geometry: {
        type: "Point",
        coordinates: [145.416527777999988, -34.854136111000003],
      },
      properties: {
        Name:
          "Viewpoint 8: View east from Conargo Road / North Boundary Road junction",
        id: "8",
      },
    },
    {
      type: "Feature",
      geometry: {
        type: "Point",
        coordinates: [145.48481666699999, -34.855319444000003],
      },
      properties: {
        Name: "Viewpoint 9: View east along four Corners Road",
        id: "9",
      },
    },
    {
      type: "Feature",
      geometry: {
        type: "Point",
        coordinates: [147.333322221999993, -35.211808333],
      },
      properties: {
        Name: "Viewpoint 17: View northeast from Holbrook Road",
        id: "17",
      },
    },
    {
      type: "Feature",
      geometry: {
        type: "Point",
        coordinates: [147.331952777999987, -35.201991667000001],
      },
      properties: {
        Name: "Viewpoint 18: View east from Holbrook Road",
        id: "18",
      },
    },
    {
      type: "Feature",
      geometry: {
        type: "Point",
        coordinates: [147.401263889000006, -35.192202778000002],
      },
      properties: {
        Name: "Viewpoint 20: View southwest from Butterbush Road",
        id: "20",
      },
    },
  ],
};
