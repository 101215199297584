import {
  SET_ACTIVE_MODAL,
  SET_MODAL_TEMPLATES,
} from "../constants/ActionTypes";

const initialState = {
  activeModalId: null,
  templates: {},
};

const modals = (state = initialState, action) => {
  switch (action.type) {
    case SET_ACTIVE_MODAL:
      return {
        ...state,
        activeModalId: action.modalId,
      };
    case SET_MODAL_TEMPLATES:
      return {
        ...state,
        templates: action.modalTemplates,
      };
    default:
      return state;
  }
};

export default modals;
