import React from "react";

import PropTypes from "prop-types";

import withDocumentData from "components/ContentGroup/DocumentWrapper";

import ButtonLink from "./ButtonLink";

const DocButtonLink = (props) => {
  const {
    documents,
    requiredDocument,
    getDocumentURLByName,
    ...buttonLinkProps
  } = props;
  const path = getDocumentURLByName(documents, requiredDocument);

  return (
    <ButtonLink path={path} {...buttonLinkProps}>
      {props.children}
    </ButtonLink>
  );
};

DocButtonLink.propTypes = {
  requiredDocument: PropTypes.string.isRequired,
  documents: PropTypes.object.isRequired,
  getDocumentURLByName: PropTypes.func.isRequired,
};

export default withDocumentData(DocButtonLink);
