// CMS action types.
export const DELETE_PAGE = "DELETE_PAGE";
export const DELETE_ROW = "DELETE_ROW";
export const SET_ACTIVE_COMPONENT_TYPE = "SET_ACTIVE_COMPONENT_TYPE";
export const SET_ACTIVE_PAGE_NAME = "SET_ACTIVE_PAGE_NAME";
export const SET_ACTIVE_ROW_ID = "SET_ACTIVE_ROW_ID";
export const SET_PAGE_CONFIG = "SET_PAGE_CONFIG";
export const SET_PAGES_CONFIG = "SET_PAGES_CONFIG";

// Interactive map action types
export const SET_LAYER_VISIBILITY = "SET_LAYER_VISIBILITY";
export const SET_TOPIC_LAYERS = "SET_TOPIC_LAYERS";

// Lightbox action types.
export const INCREMENT_LIGHTBOX_KEY = "INCREMENT_LIGHTBOX_KEY";
export const SET_LIGHTBOX_SOURCES = "SET_LIGHTBOX_SOURCES";
export const SET_LIGHTBOX_SOURCE_INDEX = "SET_LIGHTBOX_SOURCE_INDEX";
export const TOGGLE_LIGHTBOX_STATE = "TOGGLE_LIGHTBOX_STATE";

// Modal action types.
export const SET_ACTIVE_MODAL = "SET_ACTIVE_MODAL";
export const SET_MODAL_TEMPLATES = "SET_MODAL_TEMPLATES";

// Page section action types.
export const SET_ACTIVE_PAGE_SECTION = "SET_ACTIVE_PAGE_SECTION";

// Virtual Consultation Room action types.
export const SET_ACTIVE_SCENE_ID = "SET_ACTIVE_SCENE_ID";
