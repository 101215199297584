/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */
import React from "react";

import "./src/styles/index.scss";

import Footer from "components/Footer/Footer";
import Lightbox from "components/Images/Lightbox";
import ModalWrapper from "components/Modals/ModalWrapper";
import NavBar from "components/Navs/NavBar";
import Metadata from "components/Page/Metadata";
import ScrollToTop from "components/ScrollToTop/ScrollToTop";
import { CREATE_BASE_PATH, generalConfig } from "src/app-config";
import wrapWithReduxStore from "src/state";

// Wrap the root element with the Redux store context.
export const wrapRootElement = wrapWithReduxStore;

// Wrap the page with elements required on all pages.
export const wrapPageElement = ({ element, props }) => {
  const page = props.uri;
  // Don't display the footer on any dynamic/created page.
  const showNavMenus = !page.startsWith(CREATE_BASE_PATH);

  return (
    <ModalWrapper {...props}>
      <Metadata />
      {showNavMenus && <NavBar page={page} />}
      {element}
      <Lightbox />
      {showNavMenus && <Footer />}
      <ScrollToTop addOutline />
    </ModalWrapper>
  );
};

/* https://www.gatsbyjs.com/docs/browser-apis/#onClientEntry
 * This function is called when the browser runtime is intialised.
 * This is used currently to setup the Google Translate widget.
 */
export const onClientEntry = () => {
  // Check if we want to load google translate
  if (generalConfig.googleTranslate.enabled) {
    const languages = generalConfig.googleTranslate.languages
      .map((lang) => lang.code)
      .join(",");
    // First add the google translate tag
    const scriptTag = document.createElement("script");
    scriptTag.setAttribute(
      "src",
      "//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit"
    );
    scriptTag.setAttribute("key", "translate");
    document.body.appendChild(scriptTag);

    // Now setup a function to listen for google translate script to load.
    window.googleTranslateElementInit = () => {
      // Give React time to render to DOM
      window.setTimeout(() => {
        // eslint-disable-next-line no-undef
        new google.translate.TranslateElement(
          {
            pageLanguage: "en",
            includedLanguages: `${languages}`,
            // eslint-disable-next-line no-undef
            layout: google.translate.TranslateElement.InlineLayout.SIMPLE,
          },
          "google-translate-element"
        );
      }, 1000);
    };
  }

  if (generalConfig.hasLiveChat && process.env.GATSBY_CHATWOOT_WEBSITE_TOKEN) {
    const initialiseChatwoot = (parentElement, desiredTag) => {
      const BASE_URL = "https://app.chatwoot.com";
      const element = parentElement.createElement(desiredTag);
      const tag = parentElement.getElementsByTagName(desiredTag)[0];
      element.src = BASE_URL + "/packs/js/sdk.js";
      tag.parentNode.insertBefore(element, tag);
      element.onload = function () {
        window.chatwootSDK.run({
          websiteToken: process.env.GATSBY_CHATWOOT_WEBSITE_TOKEN,
          baseUrl: BASE_URL,
        });
      };
    };
    initialiseChatwoot(document, "script");
  }
};
