import React from "react";

import PropTypes from "prop-types";

import withDocumentData from "components/ContentGroup/DocumentWrapper";

import Link from "./Link";

const DocLink = (props) => {
  const {
    documents,
    requiredDocument,
    getDocumentURLByName,
    ...linkProps
  } = props;
  const path = getDocumentURLByName(documents, requiredDocument);

  return (
    <Link path={path} {...linkProps}>
      {props.children}
    </Link>
  );
};

DocLink.propTypes = {
  requiredDocument: PropTypes.string.isRequired,
  documents: PropTypes.object.isRequired,
  getDocumentURLByName: PropTypes.func.isRequired,
};

export default withDocumentData(DocLink);
